import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { EventInput } from '@fullcalendar/core';
import { Observable, combineLatest, switchMap, tap } from 'rxjs';
import { AngularFireFunctions } from '@angular/fire/compat/functions'
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { AuthService } from 'src/app/authentication/auth.service';
import { addDays, addHours, endOfWeek, format, startOfWeek } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class AppointmentsService {

  constructor(private afs: AngularFirestore, private aff: AngularFireFunctions, private notif: NzNotificationService, private auth: AuthService) { }
  currentShare = this.auth.currentShare$


  getAppAppointments(spaceID) {
    const currentDate = new Date(); // Obtén la fecha actual o la fecha deseada
    const startOfWeekDate = startOfWeek(currentDate, { weekStartsOn: 1 }); // Obtén el inicio de la semana
    const endOfWeekDate = endOfWeek(currentDate, { weekStartsOn: 1 }); // Obtén el final de la semana

    return this.afs.collection('appointments', ref => ref
      .where('spaceID', '==', spaceID)
      .where('startDate', '>', startOfWeekDate)
      .where('startDate', '<', endOfWeekDate))
      .valueChanges() as Observable<EventInput[]>


  }

  getCustomSchedulesEvents(spaceID) {
    return this.afs.collection(`spaces/${spaceID}/customSchedules`).valueChanges() as Observable<EventInput[]>
  }


  getUserAppointments(userID: string) {
    const currentDate = new Date(); // Obtén la fecha actual o la fecha deseada
    const startOfWeekDate = format(startOfWeek(currentDate, { weekStartsOn: 1 }), 'yyyy-dd-MM'); // Obtén el inicio de la semana
    const endOfWeekDate = format(endOfWeek(currentDate, { weekStartsOn: 1 }), 'yyyy-dd-MM'); // Obtén el final de la semana
    return this.afs.collection("appointments", ref => ref
      .where('user.userID', '==', userID)
      .where('start', '>', startOfWeekDate)
      .where('start', '<', endOfWeekDate)).valueChanges() as Observable<any>
  }

  createAppointment(data) {
    const resp = this.aff.httpsCallable('mobileCreateAppointment')(data).toPromise()
    return resp
  }


  getAppAppointmentsBySpaceID(spaceID) {
    const currentDate = new Date(); // Obtén la fecha actual o la fecha deseada
    const startOfWeekDate = startOfWeek(currentDate, { weekStartsOn: 1 }); // Obtén el inicio de la semana
    const endOfWeekDate = endOfWeek(currentDate, { weekStartsOn: 1 }); // Obtén el final de la semana
    return this.afs.collection('appointments', ref => ref
      .where('spaceID', '==', spaceID)
      .where('startDate', '>', startOfWeekDate)
      .where('startDate', '<', addDays(endOfWeekDate, 3)))
      .valueChanges() as Observable<any>
  }

  deleteAppointment(id) {
    return this.afs.collection('appointments').doc(id).delete()
  }


  cancelAppointments(spaceID, appointmentID) {
    return this.aff.httpsCallable('cancelAppointment')({ spaceID, appointmentID }).toPromise()
  }

  getAppointmentByID(appointmentID) {
    return this.afs.collection('appointments').doc(appointmentID).valueChanges() as Observable<any>
  }
}
