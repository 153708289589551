<ng-container
  *ngIf="{
    settings: settings$ | async,
    currentData: currentData$ | async,
    constants: constants$ | async,
    savingQR: savingQR$ | async,
    sliders: sliders$ | async
  } as state"
>
  <section
    class="flex flex-col justify-between h-full w-full"
    *ngIf="state.currentData"
  >
    <img [src]="state.sliders" class="rounded-md" />

    <div nz-row>
      <div nz-col nzSpan="24" class="p-1 flex flex-col justify-center">
        <p class="text-center">Nombre:</p>
        <span class="text-center"
          >{{ state.currentData?.user?.fname }}
          {{ state.currentData?.user?.lname }}</span
        >
      </div>
    </div>
    <div nz-row class="flex justify-between">
      <div nz-col nzSpan="6" class="p-1">
        <p>Cédula:</p>
        <span class="text-xs">{{
          state.currentData?.user?.cid | number : "1.0-0"
        }}</span>
      </div>
      <div nz-col nzSpan="6" class="p-1">
        <p>Accion:</p>
        <span class="text-xs">{{ state.currentData?.share?.shareNumber }}</span>
      </div>
      <div nz-col nzSpan="6" class="p-1">
        <p>Miembro:</p>
        <span class="text-xs">
          {{
            state.currentData.user?.isShareOwner ? "Propietario" : "Familiar"
          }}</span
        >
      </div>
    </div>
  </section>

  <div class="flex justify-center items-center h-full w-full">
    <qrcode
      *ngIf="state.currentData"
      [qrdata]="
        formatCid(
          state.currentData?.user?.cid,
          state.currentData?.user.nacionality
        )
      "
      [imageSrc]="'/assets/img/cdi-logo.png'"
      [allowEmptyString]="true"
      [imageHeight]="80"
      [imageWidth]="80"
      [width]="300"
      [cssClass]="'qrcode'"
      [colorDark]="'#000000ff'"
      [colorLight]="'#ffffffff'"
      [margin]="2"
      [errorCorrectionLevel]="'M'"
    ></qrcode>
  </div>
  <!-- <div class="flex flex-col justify-center items-center">
      <button
        *ngIf="!id"
        nz-button
        nzType="primary"
        class="my-3 rounded-xl"
        [nzLoading]="state.savingQR"
        (click)="generate(state.currentData?.user?.userID, true)"
      >
        Generar Nuevo QR
      </button>

      <qrcode
        *ngIf="id?.length > 32"
        [qrdata]="id"
        [imageSrc]="'/assets/img/cdi-logo.png'"
        [allowEmptyString]="true"
        [imageHeight]="75"
        [imageWidth]="75"
        [width]="300"
        [cssClass]="'qrcode'"
        [colorDark]="'#000000ff'"
        [colorLight]="'#ffffffff'"
        [margin]="4"
        [errorCorrectionLevel]="'M'"
      ></qrcode>

      <countdown
        [ngClass]="{ hidden: !showCD }"
        class="test drop-shadow-md"
        #cd
        [config]="config"
        (event)="handleEvent($event, state.currentData?.user?.userID)"
      ></countdown>
    </div></div>-->
</ng-container>
