import { Component, OnInit, ViewChild } from '@angular/core';
import { CountdownComponent, CountdownConfig } from 'ngx-countdown';
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  Subject,
  map,
  pluck,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { AuthService } from 'src/app/authentication/auth.service';
import { User } from 'src/app/shared/models/user.model';
import { SettingsService } from 'src/app/shared/services/settings.service';
import { UserService } from 'src/app/shared/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.scss'],
})
export class QrCodeComponent implements OnInit {
  @ViewChild('cd', { static: false }) private countdown: CountdownComponent;

  savingQR$ = new BehaviorSubject(false);
  env = environment;
  id: string;
  currentData$ = this.auth.currentData$.pipe(
    take(1),
    tap((data: any) => {
      return this.generate(data.user.userID);
    })
  );

  sliders$ = this.settingsService.getHomeInfo().pipe(
    map((congif) => {
      return congif.slider[Math.floor(Math.random() * (5 - 0 + 1) + 0)];
    })
  );

  config: CountdownConfig = {
    leftTime: 10,
    formatDate: ({ date }) => `${date / 1000}`,
  };

  constructor(
    private auth: AuthService,
    private settingsService: SettingsService,
    private userService: UserService
  ) {}
  ngOnInit(): void {}

  formatCid(n, nationality) {
    // Determina el prefijo según la nacionalidad
    const prefix = 'V-';


    // Convierte el número a string
    const nStr = String(n);

    // Define las variables para los segmentos
    let s0: string, s1: string, s2: string;

    // Realiza la lógica de formato basado en la longitud del número
    if (n < 1000000) {
      s0 = nStr.substring(0, 3);
      s1 = nStr.substring(3, 6);
      return `${prefix}${s0}.${s1}`;
    } else if (n >= 10000000) {
      s0 = nStr.substring(0, 2);
      s1 = nStr.substring(2, 5);
      s2 = nStr.substring(5, 8);
    } else {
      s0 = nStr.substring(0, 1);
      s1 = nStr.substring(1, 4);
      s2 = nStr.substring(4, 7);
    }

    // Retorna el número formateado
    return `${prefix}${s0}.${s1}.${s2}`;
  }

  generate(userID, fromClic?) {
    if (fromClic) {
      this.showCD = true;
      this.counter = 0;
      this.countdown.restart();
    }
    try {
      this.savingQR$.next(true);
      let otp = '';
      let characters = '0123456789abcdef';

      for (let i = 0; i < 32; i++) {
        let randomIndex = Math.floor(Math.random() * characters.length);
        otp += characters.charAt(randomIndex);
      }
      this.countdown.resume();
      this.id = `${otp}-${userID}`;

      this.userService.saveOTQR(this.id);
      this.savingQR$.next(false);
    } catch (error) {
      console.log(error);
    }
  }

  settings$ = this.settingsService.getSettings();
  constants$ = this.settingsService.getConstants();

  showCD = true;
  counter = 0;
  handleEvent(e, userID) {

    if (this.counter === 2) {

      this.countdown.stop();
      this.userService.deleteOTQR(this.id);
      this.showCD = false;
      this.id = '';
    }

    if (e.action === 'done') {
      this.generate(userID);

      this.countdown.restart();
      this.counter++;
    }
  }
}
