import { Injectable, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  filter,
  from,
  map,
  observable,
  of,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { UserService } from '../shared/services/user.service';
import { User } from '../shared/models/user.model';
import { SharesService } from '../shared/services/shares.service';
import { getAuth } from 'firebase/auth';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Location } from '@angular/common';
import { Share } from '../shared/models/share.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnInit {
  currentData$ = new Observable<any>();
  currentUser$ = new Observable<User>();
  currentShareHolder$ = new Observable<User>();
  currentAuth$ = new Observable<any>();
  currentShare$ = new Observable<Share>();

  constructor(
    private afAuth: AngularFireAuth,
    private userService: UserService,
    private shareService: SharesService,
    private firestore: AngularFirestore,
    private router: Router,
    private location: Location,
    private notif: NzNotificationService
  ) {
    this.currentData$ = this.afAuth.authState.pipe(
      switchMap((data) => {
        if (data) {
          const userID = data.uid;
          this.currentUser$ = this.userService.getUserById(userID);
          this.currentShare$ = this.currentUser$.pipe(
            tap((user) => {
              if (!user || !user.isActive || !user.shareID || !user.isAsigned) {
                this.afAuth.signOut();
                this.notif.error('Ups..', `no tienes acceso o accion asignada`);
              }
            }),
            switchMap((user) => {
              return this.shareService
                .getShareByShareID(user.shareID)
                .pipe(map((user) => user[0]));
            })
          );
          this.currentAuth$ = this.currentShare$.pipe(
            switchMap((share) => {
              const authIds = share.authorized;
              return this.userService.getAuthorizedUsers(authIds);
            })
          );
          this.currentShareHolder$ = this.currentShare$.pipe(
            switchMap((share) => {
              const shareOwnerID = share.currentShareHolder;
              return this.userService.getUserById(shareOwnerID);
            })
          );
          return combineLatest([
            this.currentUser$,
            this.currentShare$,
            this.currentAuth$,
            this.currentShareHolder$,
          ]).pipe(
            map(([user, share, auth, owner]) => {
              return { user, share, auth, owner };
            })
          );
        } else {
          return this.router
            .navigate(['/'])
            .then(() => window.location.reload());
        }
      })
    );
  }
  ngOnInit(): void {}

  async login(email: string, password: string): Promise<Boolean> {
    return new Promise((resolve, reject) => {
      this.afAuth
        .signInWithEmailAndPassword(email, password)
        .then((resp) => {
          if (resp) {
            this.router.navigate(['/home']);
            resolve(true);
          }
          reject(false);
        })
        .catch((error) => {
          switch (error.code) {
            case 'auth/invalid-password':
              this.notif.error(
                'Ups...',
                `Contraseña Invalida, intente de nuevo.`
              );
              reject(false);
              break;
            case 'auth/wrong-password':
              this.notif.error(
                'Ups...',
                `Contraseña Invalida, intente de nuevo.`
              );
              reject(false);
              break;

            case 'auth/too-many-requests':
              this.notif.error(
                'Upss...',
                'Demasiados intentos, esta cuenta ha sido deshabilitada temporalmente por seguridad, puede habilitarla tde nuevo restableciendo su contraseña o intentar nuevamente mas tarde'
              );
              reject(false);
              break;
            case 'auth/user-not-found':
              this.notif.error(
                'Upss...',
                'El usuario no existe, por favor verifique su correo. si el problema persiste favor contactar soporte.'
              );
              reject(false);
              break;
          }
          // console.log({ error }, ' aloooo');
          // this.notif.error('Ups..', `${error.code}`);
        });
    });
  }

  logOut() {
    return this.afAuth.signOut().then(() => this.router.navigate(['/login']));
  }
}
