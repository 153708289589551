<ng-container
  *ngIf="{
    input: inputData$ | async,
    currentUser: currentUser$ | async,
    currentShare: currentShare$ | async,
    gameModesMap: gameModesMap$ | async,
    isLoadingUsers: isLoadingUsers$ | async,
    bookingRules: bookingRules$ | async
  } as state"
>
  <div class="container h-[(100vh-70px)] pb-[300px]">
    <form nz-form [formGroup]="shareForm">
      <p class="mb-2">
        Para crear tu agenda en {{ state.input?.space[0]?.spaceName }} debes
        seleccionar el modo de juego, recuerda que en cualquier caso, ya tú
        estás incluido.
      </p>

      <div class="relative">
        <div class="absolute mx-auto w-full">
          <div
            nz-row
            class="flex flex-col bg-opacity-30 drop-shadow-lg p-2 rounded-lg animated-bg"
          >
            <div nz-col nzSpan="24">
              <div class="flex flex-col py-2 usercard">
                <div class="col-12 profile-sidebar">
                  <div class="flex flex-col justify-center items-center">
                    <div class="col-auto">
                      <figure
                        class="avatar avatar-80 rounded-18 shadow-sm p-1 bg-white"
                      >
                        <img
                          [src]="state.currentUser?.profilePhoto"
                          alt=""
                          class="rounded-15"
                        />
                      </figure>
                    </div>
                    <div
                      class="col px-0 font-bold align-self-center w-full pt-3 text-white mx-auto text-center"
                    >
                      <h5>
                        {{ state.currentUser?.fname ?? "" }}
                      </h5>
                      <!-- <p class="m-0">
            Accion:
            {{ state.currentUser?.shareNumber | number : "1.0-0" }}
          </p> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <svg
          width="100%"
          height="100%"
          id="svg"
          viewBox="0 0 1440 690"
          xmlns="http://www.w3.org/2000/svg"
          class="transition duration-300 ease-in-out delay-150"
        >
          <path
            d="M 0,700 L 0,105 C 153.7333333333333,126.73333333333333 307.4666666666666,148.46666666666667 465,142 C 622.5333333333334,135.53333333333333 783.8666666666668,100.86666666666667 947,90 C 1110.1333333333332,79.13333333333333 1275.0666666666666,92.06666666666666 1440,105 L 1440,700 L 0,700 Z"
            stroke="none"
            stroke-width="0"
            fill="#0693e3"
            fill-opacity="0.265"
            class="transition-all duration-300 ease-in-out delay-150 path-0"
          ></path>
          <path
            d="M 0,700 L 0,245 C 180.53333333333336,219.93333333333334 361.0666666666667,194.86666666666665 502,206 C 642.9333333333333,217.13333333333335 744.2666666666667,264.4666666666667 894,277 C 1043.7333333333333,289.5333333333333 1241.8666666666668,267.26666666666665 1440,245 L 1440,700 L 0,700 Z"
            stroke="none"
            stroke-width="0"
            fill="#0693e3"
            fill-opacity="0.4"
            class="transition-all duration-300 ease-in-out delay-150 path-1"
          ></path>
          <path
            d="M 0,700 L 0,385 C 120.80000000000001,412.3333333333333 241.60000000000002,439.66666666666663 416,427 C 590.4,414.33333333333337 818.4000000000001,361.6666666666667 998,348 C 1177.6,334.3333333333333 1308.8,359.66666666666663 1440,385 L 1440,700 L 0,700 Z"
            stroke="none"
            stroke-width="0"
            fill="#0693e3"
            fill-opacity="0.53"
            class="transition-all duration-300 ease-in-out delay-150 path-2"
          ></path>
          <path
            d="M 0,700 L 0,525 C 147.7333333333333,516.7333333333333 295.4666666666666,508.46666666666664 473,506 C 650.5333333333334,503.53333333333336 857.8666666666666,506.8666666666667 1024,511 C 1190.1333333333334,515.1333333333333 1315.0666666666666,520.0666666666666 1440,525 L 1440,700 L 0,700 Z"
            stroke="none"
            stroke-width="0"
            fill="#0693e3"
            fill-opacity="1"
            class="transition-all duration-300 ease-in-out delay-150 path-3"
          ></path>
        </svg>
      </div>

      <div class="flex flex-col pt-2 justify-center items-center">
        <nz-form-item class="flex flex-col justify-center items-center">
          <nz-form-control>
            <p>Selecciona el Modo de juego</p>
            <nz-radio-group
              formControlName="playerCount"
              class="space-x-3 flex items-center justify-center"
              nzSize="large"
            >
              <label
                class="rounded-pill"
                *ngFor="
                  let mode of state.input.space[0].gameMode;
                  let i = index
                "
                nz-radio-button
                [nzValue]="mode"
              >
                <p
                  *ngIf="mode === 1"
                  class="flex items-center space-x-2 font-bold"
                >
                  1
                  <span class="inline-flex px-2">
                    <img src="/assets/img/vs.svg" width="20" alt="" srcset=""
                  /></span>
                  1
                </p>
                <p
                  *ngIf="mode > 1"
                  class="flex items-center space-x-2 font-bold"
                >
                  {{ (mode + 1) / 2 }}
                  <span class="inline-flex px-2">
                    <img src="/assets/img/vs.svg" width="20" alt="" srcset=""
                  /></span>
                  {{ (mode + 1) / 2 }}
                </p>
              </label>
            </nz-radio-group>
          </nz-form-control>
        </nz-form-item>
      </div>

      <nz-form-item class="mb-2" *ngIf="shareForm.get('playerCount').value">
        <nz-form-label
          class="font-bold text-xl"
          [nzSpan]="24"
          nzFor="description"
          >Numero de Acción:</nz-form-label
        >
        <nz-form-control class="rounded-lg">
          <nz-input-group [nzSuffix]="inputClearTpl">
            <input
              type="number"
              nz-input
              formControlName="shareNumber"
              placeholder="Ingrese el número de Acción"
            />
          </nz-input-group>
          <ng-template #inputClearTpl>
            <span *ngIf="state.isLoadingUsers" class="me-3">
              <nz-spin></nz-spin>
            </span>
            <span
              nz-icon
              class="ant-input-clear-icon"
              nzTheme="fill"
              nzType="close-circle"
              *ngIf="shareForm.get('shareNumber').value"
              (click)="softDelete()"
              >Borrar</span
            >
          </ng-template>
        </nz-form-control>
      </nz-form-item>
      <div *ngIf="usersGuests?.length < shareForm.get('playerCount').value">
        <ul *ngIf="users?.length > 0">
          @if (users?.length >= 5) {
          <div>
            <input
              nz-input
              type="text"
              placeholder="Búsqueda Rápida"
              [(ngModel)]="searchTerm"
              [ngModelOptions]="{ standalone: true }"
            />
          </div>
          }
          <label for="searchUser" *ngIf="users?.length > 0"
            >Selecciona tus Invitados ({{ usersGuests?.length }}):</label
          >
          <ng-container
            *ngFor="
              let user of users | filter : 'fname' : searchTerm;
              let i = index;
              trackBy: identify
            "
          >
            <li *ngIf="user.userID">
              <ng-container *ngIf="user.userID !== state.currentUser.userID">
                <label>
                  <input
                    type="checkbox"
                    [disabled]="user.disabled"
                    (change)="
                      selectUser(user, i, state.bookingRules?.maxNotShow)
                    "
                  />
                  <div class="row mb-3 usercard">
                    <div class="col-12 profile-sidebar">
                      <div class="row">
                        <div class="col-auto">
                          <figure
                            [ngClass]="{ selected: user.selected }"
                            class="avatar avatar-80 rounded-18 shadow-sm p-1 bg-white"
                          >
                            <img
                              [src]="user?.profilePhoto"
                              alt=""
                              class="rounded-15"
                            />
                          </figure>
                        </div>
                        <div class="col px-0 align-self-center w-full">
                          <h5>{{ user?.nickname }}</h5>
                          <p class="m-0">
                            Accion: {{ user?.shareNumber | number : "1.0-0" }}
                            <span *ngIf="user.missingInfo">⚠️</span>
                            <span
                              *ngIf="
                                user?.notShowCounter >=
                                state.bookingRules?.maxNotShow
                              "
                              >❌</span
                            >
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </label>
              </ng-container>
            </li>
          </ng-container>
        </ul>
      </div>

      <div>
        <div
          *ngIf="usersGuests?.length > 0"
          class="flex justify-between items-center"
        >
          <h4 class="mb-1">Usuarios Invitados ({{ usersGuests?.length }}):</h4>
          <button
            nz-button
            nzDanger
            nzSize="small"
            nzShape="round"
            (click)="startOver()"
          >
            Comenzar de nuevo
          </button>
        </div>
        <div *ngFor="let user of usersGuests; let i = index">
          <ng-container *ngIf="user.userID">
            <div class="flex justify-between items-center w-auto">
              <div class="flex items-center">
                <div nz-col>
                  <div class="avatar avatar-80 rounded-18 shadow-sm me-3">
                    <img [src]="user?.profilePhoto" alt="" class="rounded-15" />
                  </div>
                </div>
                <div nz-col>
                  <h5>{{ user?.fname }} {{ user?.lname }}</h5>
                  <p class="m-0">
                    Accion: {{ user?.shareNumber | number : "1.0-0" }}
                  </p>
                </div>
              </div>
              <div nz-col>
                <button
                  type="button"
                  nzSize="small"
                  (click)="removeUser(i, user)"
                >
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 80 80"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M61 20L56.3735 64.4144C56.1612 66.4521 54.4437 68 52.395 68H27.605C25.5563 68 23.8388 66.4521 23.6265 64.4144L19 20"
                      stroke="red"
                      stroke-linecap="round"
                      stroke-width="5%"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M65 20H15"
                      stroke="red"
                      stroke-linecap="round"
                      stroke-width="5%"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M27.8555 19.9986L33.926 12.3865H46.0747L52.1452 19.9986"
                      stroke-width="5%"
                      stroke="red"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <div class="flex justify-end gap-3">
        <button
          nz-button
          nzType="link"
          [nzDanger]="usersGuests?.length > 0"
          (click)="askBeforeOut()"
        >
          Volver
        </button>
        <button
          nz-button
          nzType="primary"
          nzShape="round"
          class="float-right"
          *ngIf="shareForm.get('playerCount').value"
          [disabled]="
            usersGuests?.length < shareForm.get('playerCount').value ||
            !shareForm.get('playerCount').value
          "
          (click)="
            onSubmit(state.currentUser, state.currentShare, state.input.space)
          "
        >
          Crear Reserva
        </button>
      </div>
    </form>
  </div>
</ng-container>
