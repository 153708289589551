import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import {
  BehaviorSubject,
  combineLatest,
  startWith,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

import { environment } from '../../../environments/environment';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AuthService } from 'src/app/authentication/auth.service';
import { FieldValue } from 'firebase/firestore';
import { arrayUnion } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationService {
  currentMessage = new BehaviorSubject(null);

  constructor(
    private afMessaging: AngularFireMessaging,
    private afs: AngularFirestore,
    private auth: AuthService
  ) {}

  async requestPermissions() {
    await window.Notification.requestPermission();

    // If the user rejects the permission result will be "denied"

    this.afMessaging.requestPermission.subscribe(
      async (permission) => {
        const savingToken$ = combineLatest([
          this.afMessaging.getToken,
          this.auth.currentData$,
        ])
          .pipe(
            take(1),
            switchMap(([token, data]: any) => {
              const userRef = this.afs
                .collection('users')
                .doc(data.user.userID);
              return userRef.update({ gcm_token: arrayUnion(token) });
            })
          )
          .subscribe();
      },
      (err) => {
        console.log('no hay permisos', err);
      }
    );
  }

  receiveMessaging() {
    this.afMessaging.messages.subscribe((payload) => {
      console.log('Nuevo mensaje', payload);
      this.currentMessage.next(payload);
    });
  }
}
