import { NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CreateAppointmentRoutingModule } from './create-appointment-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { CreateAppointmentComponent } from './create-appointment.component';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { FilterPipe } from '../../../../../shared/pipes/filter-name.pipe';

@NgModule({
  declarations: [CreateAppointmentComponent],
  schemas: [NO_ERRORS_SCHEMA],
  imports: [
    CommonModule,
    CreateAppointmentRoutingModule,
    ReactiveFormsModule,
    NzFormModule,
    NzRadioModule,
    FormsModule,
    NzSelectModule,
    NzInputModule,
    NzButtonModule,
    NzModalModule,
    NzSpinModule,
    NzDrawerModule,
    FilterPipe,
  ],
})
export class CreateAppointmentModule {}
