import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EtixQrRoutingModule } from './qr-code-routing.module';
import { SocialMediaComponent } from './components/qr-social-media/social-media.component';
import { QrCodeComponent } from './qr-code.component';
import { QrGeneratedComponent } from './components/qr-generated/qr-generated.component';
import { QRCodeModule } from 'angularx-qrcode';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { CountdownModule } from 'ngx-countdown';

@NgModule({
  declarations: [
    SocialMediaComponent,
    QrCodeComponent,
    QrGeneratedComponent,
  ],
  imports: [
    CommonModule,
    QRCodeModule,
    EtixQrRoutingModule,
    NzButtonModule,
    CountdownModule

  ], schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class QrCodeModule { }
