import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavigationStart, Router } from '@angular/router';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  share,
  startWith,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { AuthService } from 'src/app/authentication/auth.service';
import { Share } from 'src/app/shared/models/share.model';
import { User } from 'src/app/shared/models/user.model';
import { AppointmentsService } from 'src/app/shared/services/appointments.service';
import { SettingsService } from 'src/app/shared/services/settings.service';
import { SharesService } from 'src/app/shared/services/shares.service';
import confetti from 'canvas-confetti';

@Component({
  selector: 'app-create-appointment',
  templateUrl: './create-appointment.component.html',
  styleUrls: ['./create-appointment.component.scss'],
})
export class CreateAppointmentComponent implements OnInit {
  inputData$ = new BehaviorSubject(null);
  isLoading$ = new BehaviorSubject(false);
  isLoadingUsers$ = new BehaviorSubject(false);
  searchTerm: string = '';
  minGuest: number = null;
  dateString: string;
  spaceID: string;
  currentshare: Share = null;
  currentUser$ = this.auth.currentUser$;
  bookingRules$ = this.settingsService.getBookingRules();
  currentShare$ = this.auth.currentShare$;
  selectedValue;
  shareForm: FormGroup;
  users: any[]; // Array para almacenar los usuarios seleccionados

  minimumUsers: number = 0; // Mínimo requerido de usuarios seleccionados

  gameModesMap$ = this.settingsService.getGameModes();
  @Input() set data(value: any) {
    this.dateString = value.dateStr;
    this.spaceID = value.space[0].spaceID;
    this.minimumUsers = value.space[0].minGuest;
    this.currentshare = value.share;
    this.inputData$.next(value);
  }

  mapping = {
    '=1': 'Persona',
    other: 'Personas',
  };
  constructor(
    private fb: FormBuilder,
    private settingsService: SettingsService,
    private shareService: SharesService,
    public nzDrawerRef: NzDrawerRef,
    private aps: AppointmentsService,
    private notif: NzNotificationService,
    private firestore: AngularFirestore,
    private auth: AuthService,

    private modalservice: NzModalService,
    private router: Router
  ) {
    this.shareForm = this.fb.group({
      shareNumber: ['', Validators.required],
      space: [''],
      createdBy: this.fb.group({
        user: [{}],
        share: [{}],
      }),
      start: [''],
      guests: [[]],
      playerCount: [0],
    });
  }

  ngOnInit(): void {
    this.shareForm.controls['shareNumber'].valueChanges
      .pipe(debounceTime(500))
      .subscribe((shareNumber) => {
        if (!shareNumber && this.users?.length > 0) {
          this.users = [];
        }
        if (this.shareForm.valid) {
          this.searchShare(shareNumber);
        }
      });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        // Show progress spinner or progress bar
        this.nzDrawerRef.close();
      }
    });
  }

  searchShare(shareNumber: number) {
    this.isLoadingUsers$.next(true);
    // Aquí realizas la búsqueda del documento en Firestore
    this.firestore
      .collection<Share>('shares', (ref) =>
        ref.where('shareNumber', '==', shareNumber).limit(1)
      )
      .valueChanges()
      .pipe(take(1))
      .subscribe({
        next: (doc) => {
          const shareData = doc[0];

          if (
            !shareData ||
            !shareData.currentShareHolder ||
            !shareData.isSolvent
          ) {
            this.invalidSelection();
            return;
          }

          const usersID = [
            shareData.currentShareHolder,
            ...shareData.authorized,
          ];

          //remove currentShareHolder if is juridic
          if (shareData.type === 'Jurídica') {
            usersID.shift();
          }

          this.users = [];

          usersID.forEach((id: string) => {
            this.firestore
              .collection<Partial<User | Record<string, any>>>('users')
              .doc(id)
              .get()
              .subscribe((userSnapshot) => {
                let userData = userSnapshot.data();
                const [handler, domain] = userData.email.split('@');

                let selectedGuestID = this.usersGuests.map(
                  (user) => user.userID
                );

                if (
                  usersID.length === 1 &&
                  domain === 'cdimcbo.com' &&
                  userData.isShareOwner
                ) {
                  this.invalidSelection();
                  return;
                }
                //filter users with fakemail
                if (domain === 'cdimcbo.com') {
                  userData = { ...userData, missingInfo: true };
                }

                //filter user if is already selected
                if (selectedGuestID.includes(userData.userID)) {
                  userData = { ...userData, selected: true, disabled: true };
                  return;
                }

                this.users.push(userData);
              });
          });
        },

        error: (e) => {
          return this.notif.blank(
            'Este usuario ya esta incluido en sus invitados',
            e,
            { nzAnimate: true }
          );
        },
        complete: () => {
          this.isLoadingUsers$.next(false);
        },
      });
  }

  usersGuests: User[] = [];

  identify(item, i) {
    return item[i];
  }

  selectUser(user: any, i: number, maxNotShow: number) {
    if (user.missingInfo || user.notShowCounter >= maxNotShow) {
      this.notif.blank(
        'Este usuario no puede ser invitado',
        'por favor seleccione otro',
        { nzAnimate: true }
      );
      return;
    }
    let addPropIndex: number;
    let isInList = this.usersGuests.some((user1, index) => {
      if (this.users[index]?.userID === user1.userID) {
        addPropIndex = index;
        this.users[index] = { ...this.users[index], selected: true };
      }
      return user1.userID === user.userID;
    });

    if (isInList) {
      this.shareForm.get('shareNumber').updateValueAndValidity();
      this.notif.blank(
        'Este usuario ya esta incluido en sus invitados',
        'Refrescando busqueda...',
        { nzAnimate: true }
      );
      this.users[addPropIndex] = {
        ...this.users[addPropIndex],
        selected: true,
      };
      return;
    }

    this.usersGuests.push(user);
    this.users[i] = { ...this.users[i], disabled: true, selected: true };
  }

  softDelete() {
    this.shareForm.get('shareNumber').setValue(null);
    this.users = null;
    this.searchTerm = '';
    this.isLoadingUsers$.next(false);
  }

  removeUser(index: number, user) {
    this.usersGuests.splice(index, 1);
    // let currentValueOfShareForm = this.shareForm.get('shareNumber').value;
    //   this.shareForm.get('shareNumber').setValue(currentValueOfShareForm);
    this.shareForm.get('shareNumber').updateValueAndValidity();
  }
  startOver() {
    this.users = [];
    this.shareForm.get('shareNumber').setValue(null);
    this.usersGuests = [];
    this.searchTerm = '';
    this.isLoadingUsers$.next(false);
  }
  onSubmit(currentUser, share, space) {
    this.modalservice.create({
      nzTitle: 'Terminos y Condiciones',
      nzContent: space[0]?.termsAndConditions,
      nzCancelText: 'Volver',
      nzOkText: this.isLoading$.value ? 'Espere por favor...' : 'Acepto',
      nzBodyStyle: {
        height: '50vh',
        overflow: 'scroll',
      },

      nzOnOk: async () => {
        this.isLoading$.next(true);

        // this.notif.info('Estamos anotando tu agenda, por favor espera...', '');
        this.shareForm.get('start').setValue(this.dateString);
        this.shareForm.get('space').setValue(space[0]);
        this.shareForm.get('createdBy.user').setValue(currentUser);
        this.shareForm.get('createdBy.share').setValue(share);
        this.shareForm.get('guests').setValue(this.usersGuests);

        try {
          const resp = await this.aps.createAppointment(this.shareForm.value);
          if (resp) {
            this.notif.success('Listo', resp.message, { nzDuration: 1000 * 6 });
            this.nzDrawerRef.close();

            // Add confetti effect
            const duration = 1 * 1000; // Duration of confetti effect in milliseconds
            const animation = { start: null };
            const end = Date.now() + duration;

            const interval = setInterval(() => {
              if (Date.now() > end) {
                return clearInterval(interval);
              }

              confetti({
                particleCount: 100, // Number of confetti particles
                spread: 70, // Spread of confetti particles
                origin: { y: 0.9 }, // Starting position of confetti particles
                duration: 2000, // Duration of the confetti effect in milliseconds
              });
            }, 200);

            // Stop confetti effect after duration
            setTimeout(() => {
              clearInterval(interval);
            }, duration);
          }
        } catch (error) {
          console.log(error);
          this.isLoading$.next(false);

          this.notif.error('Ups..', error.message);
        }
      },
    });
  }

  invalidSelection() {
    this.isLoadingUsers$.next(false);
    this.searchTerm = '';
    this.shareForm.get('shareNumber').reset();
    this.shareForm.get('shareNumber').setValue(null);
    this.notif.info(
      'Ésta acción no está disponible 😥',
      'por favor, intente con otra.'
    );
  }

  askBeforeOut() {
    if (this.usersGuests.length === 0) {
      return this.nzDrawerRef.close();
    }
    this.modalservice.confirm({
      nzOkText: 'Sí, Salir.',
      nzTitle:
        'Esta en medio de la creación de una Reserva, está seguro que desea Salir?',
      nzOnOk: () => {
        this.nzDrawerRef.close();
      },
    });
  }
}
