import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {QrCodeComponent} from './qr-code.component'

const routes: Routes = [
  {
    path: '',
    component: QrCodeComponent,
  },
  {
    path: '',
    children: [
    ]
  }


];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EtixQrRoutingModule { }
