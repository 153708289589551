import { Component, Input } from '@angular/core';

@Component({
  selector: 'qr-social-media',
  templateUrl: './social-media.component.html',
  styleUrls: ['./social-media.component.scss']
})
export class SocialMediaComponent {
  @Input () socialMedia!:{name:string,url:string};
}
