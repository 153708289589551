import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { User } from '../models/user.model';
import { Observable, first, map, tap } from 'rxjs';
import { FieldPath, FieldValue, Firestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private angularFireStore: AngularFirestore) { }

  getUserDataByMail(email: string) {
    return this.angularFireStore.collection<User>('users', ref => ref.where('email', '==', email)).valueChanges() as Observable<User[]>
  }

  getAuthorizedUsers(ids: any) {
    if (ids.length === 0) return [null]
    return this.angularFireStore.collection('users', ref => ref.where('userID', 'in', ids)).valueChanges();
  }


  getUserById(userID: any) {
    return this.angularFireStore
      .collection<User>('users', ref => ref.where("userID", '==', userID))
      .valueChanges().pipe(
        map((users) => users[0])
      )
  }

  saveOTQR(generateID: string) {
    const [token, userID] = generateID.split("-")
    return this.angularFireStore.collection('users').doc(userID).set({ OTPQR: token }, { merge: true })
  }

  deleteOTQR(generateID: string) {
    const [token, userID] = generateID.split("-")
    if (!userID) {
      return null
    }
    return this.angularFireStore.collection('users').doc(userID).update({ OTPQR: null })
  }
}
