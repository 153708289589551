import { Component, Input } from '@angular/core';
import { AuthService } from 'src/app/authentication/auth.service';

@Component({
  selector: 'qr-generated',
  templateUrl: './qr-generated.component.html',
  styleUrls: ['./qr-generated.component.scss']
})
export class QrGeneratedComponent {

  currentUser$ = this.auth.currentUser$
  constructor(private auth: AuthService) { }
}
