import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable, tap } from 'rxjs';
import { Share } from '../models/share.model';

@Injectable({
  providedIn: 'root'
})
export class SharesService {

  constructor(private afs: AngularFirestore) { }


  getShareByShareID(shareID: any) {
    return this.afs.collection('shares', ref => ref.where('shareID', '==', shareID)).valueChanges() as Observable<any>
  }

  getValidShares() {
    return this.afs.collection<Share>('shares', ref => ref.where('isSolvent', '==', true).where('currentShareHolder', '!=', null)).valueChanges().pipe(
      tap((shares: Share[]) => {
        return shares.sort((a, b) => a.shareNumber - b.shareNumber)
      })
    )
  }
}
